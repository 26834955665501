import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VendorService {
  private urlVendor = environment.vendorUrl;
  constructor(private http: HttpClient) { }

  public quantityToBeShipped(vendorId: number):Observable<number>{
    const url = `${this.urlVendor}/shipped/${vendorId}`;
    return this.http.get<number>(url);
  }

  private despachoUpdated = new BehaviorSubject<void>(undefined); // Observable para cuando se actualiza el despacho

  // Observable al cual otros componentes se pueden suscribir
  despachoUpdated$ = this.despachoUpdated.asObservable();

  // Método para emitir el evento
  notifyDespachoUpdate() {
    this.despachoUpdated.next();
  }
}
